import {
  Map,
  createMap,
  createPointTemplate,
  addPointToMap,
  createClusterPoints,
  createPoint,
  addGeoObjectsToCluster,
  zoomIn,
  zoomOut,
  createMinValueFilter,
  addFilter,
  addFilterCurrency
} from './customYmap/customYmap';

Map.prototype = {
  constructor: Map,
  createMap,
  createPointTemplate,
  addPointToMap,
  createClusterPoints,
  createPoint,
  addGeoObjectsToCluster,
  zoomIn,
  zoomOut,
  createMinValueFilter,
  addFilter,
  addFilterCurrency,
};

export default Map;
