export function Map(domElem) {
  this.elem = domElem;
}


export function createMap(mapOptions, mapParam) {
  this.map = new ymaps.Map(this.elem, mapOptions, mapParam); // eslint-disable-line
}


export function createPointTemplate(template, name, eventHandlers) {
  const templateLayout = ymaps.templateLayoutFactory.createClass( // eslint-disable-line
      template,
      eventHandlers && (eventHandlers.length > 0)
        ? {
          build() {
            templateLayout.superclass.build.call(this);
            const point = this.getParentElement();
            eventHandlers.forEach((handler) => {
              this.getData().geoObject.events.add(handler.nameEvent, handler.callback.bind(this, point));
            });
          },
        }
        : ''
  );

  this[name] = templateLayout;
}


export function addPointToMap(placemark) {
  // var placemark = new ymaps.Placemark(coords, data,
  //   {
  //     ...layoutParam,
  //     iconLayout: layout,
  //   }
  // )
  this.map.geoObjects.add(placemark);
}

export function createPoint(coords, data, layoutParam, layout) {
  let placemark = new ymaps.Placemark(coords, data, // eslint-disable-line
      {
        ...layoutParam,
        iconLayout: layout,
      }
  );
  return placemark;
}

export function addGeoObjectsToCluster(clusterName, geoObjects) {
  this[clusterName].removeAll();
  this[clusterName].add(geoObjects);
  // this.map.setBounds(cluster.getBounds(), {
  //   checkZoomRange: true,
  //   zoomMargin: 20,
  // }).then(() => {
  //   if(geoObjects.length === 1) this.map.setZoom(this.zoom);
  // });
}

export function createClusterPoints(layout, clusterName) {
  const cluster = new ymaps.Clusterer({ // eslint-disable-line
    clusterIconLayout: layout,
    clusterIconShape: {
      type: 'Rectangle',
      coordinates: [[0, 0], [45, 56]],
    },
    maxZoom: 20,
    hasBalloon: false,
    groupByCoordinates: false,
    // clusterDisableClickZoom: true,
    clusterHideIconOnBalloonOpen: true,
    geoObjectHideIconOnBalloonOpen: false,
  });

  this[clusterName] = cluster;
}

export function zoomIn() {
  const zoom = this.map.getZoom();
  this.map.setZoom(zoom + 1);
}

export function zoomOut() {
  const zoom = this.map.getZoom();
  this.map.setZoom(zoom - 1);
}

export function createMinValueFilter(propName, format) {
  return function (data, objects, filterValue) { // eslint-disable-line
    const values = [];
    objects.forEach((obj) => {
      values.push(obj.properties.get(propName));
    });
    let min = Math.min.apply(null, values);

    switch (format) {
      case 'currency':
        min = new Intl.NumberFormat('ru-Ru').format(min);
        break;
      default:
        break;
    }
    return min;
  };
}

export function addFilter(alias, filter) {
  ymaps.template.filtersStorage.add(alias, filter); // eslint-disable-line
}

export function addFilterCurrency() {
  const currency = function (data, price, filterValue) { // eslint-disable-line
    return new Intl.NumberFormat('ru-Ru').format(price);
  };

  this.addFilter('currency', currency);
}
